/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/customParseFormat";
import { Link } from "react-router-dom";
import Return from "../../Assets/Return_Symbol.svg";
import WeeklyCalendar from "../../Assets/WeeklyCalendar.svg";
import "./Schedules.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarItems } from "../../login";
import SuccessModal from "./SuccessModal";

dayjs.extend(advancedFormat);

const localizer = momentLocalizer(moment);

// const events = [
//   { start: new Date(), end: new Date(), title: "special event" }
// ];

const DnDCalendar = withDragAndDrop(Calendar);

const CalendarData = CalendarItems;

class Schedules extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: this.prepareCalendarData(props.calendarData)
    };
    // const { events } = this.state;
  }

  prepareCalendarData = (data) => {
    const events = [];
    for (let i = 0; i < data.length; i++) {
      events.push({
        start: new Date(data[i].start.dateTime),
        end: new Date(data[i].end.dateTime),
        title: data[i].summary,
      });
    }
    return events;
  };

  onEventResize = (data) => {
    const { start, end } = data;

    this.setState((state) => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };

  onEventDrop = (data) => {
    console.log("OnEventDrop: ", data);
    let evts = [...this.state.events]
    evts.forEach((evt, index) => {
      if(evt.summary === data.event.summary && evt.start == data.event.start && evt.end == data.event.end){
        evts[index].start = data.start;
        evts[index].end = data.end;
      }
    })
    this.setState(state => {
      return {events: evts}
    })
  };

 

  
  render() {

    return (
      <div>
        <div className="todoapp stack-large text-center">
          <h1 className="schedules-header">Your my time calendar is ready</h1>
          <h2 className="schedules-header-2">
            Rejig until you find what works best for you
          </h2>
          <DnDCalendar
            defaultDate={moment().toDate()}
            defaultView="month"
            events={this.state.events}
            localizer={localizer}
            onEventDrop={this.onEventDrop}
            onEventResize={this.onEventResize}
            resizable
            style={{ height: "50vh", width: "70vw" }}
          />
          <div className="text-center">
            <Link
              className="Activity__Link--Button"
              id="link-to-google-calendar"
              onClick={() => this.props.createGMAILEvents()}
            >
              <h3 className="Activity__Link--Button">Add to Calendar</h3>
            </Link>
          </div>
          <div className="text-center">
            <SuccessModal />
            <Link
              className="Shuffle__Calendar--Button"
              id="link-to-shuffle-calendar"
              onClick={() => this.props.rejig()}
            >
              <h3 className="" id="ShuffleCalendar">
                Rejig
              </h3>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Schedules;
