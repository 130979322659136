/** @format */

import React, { useState } from "react";
import SmileyFace from "../../Assets/Smiley_Face.svg";
import Emojis from "./Emojis";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

function ActivityForm(props) {
  const [value, setValue] = useState("");
  const { createNewActivity, setEditing } = props;

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    createNewActivity(value);
    setEditing(false);

    setValue("");
  }
  function handleClick(event) {
    event.preventDefault();
    alert("You want to choose an emoji!");
  }

  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <img
      className="Navbar__Right--Hamburger"
      src={SmileyFace}
      alt="Smiley face"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
  ));

  return (
    <form onSubmit={handleSubmit}>
      <input
        id="new-todo-input"
        className="Activity__Input--Text-Input"
        name="text"
        autoComplete="off"
        value={value}
        onChange={handleChange}
        className="Activity__Input--Text-Input"
        type="text-area"
        placeholder="e.g. Painting, Journaling, Coding, etc."
        autoFocus="on"
      />
      {/* <img className="Activity__Input--SmileyFace" src={SmileyFace} alt="" /> */}
      {/* <Dropdown className="Activity__Input--SmileyFace">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <img
            className="Activity__Input--SmileyFace"
            src={SmileyFace}
            alt=""
            onClick={handleClick}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className="Navbar__dropdown--Menu">
          <Dropdown.Item eventKey="1">
            <Emojis />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
    </form>
  );
}

export default ActivityForm;
