/** @format */

import React, { useState, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import {useHistory} from 'react-router-dom';
import GoogleLogo from '../src/Assets/google-logo.png';
import {
  getEvents,
  accessToken,
  setToken,
} from "./CalendarHelper";
import "./Home.css";

export const refreshTokenSetup = (res) => {
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    console.log("newAuthRes:", newAuthRes);
    console.log("new auth Token:", newAuthRes.id_token);

    setTimeout(refreshToken, refreshTiming);
  };

  setTimeout(refreshToken, refreshTiming);
};

export let CalendarItems = "";
export let email = "";
export let name = "";

function Login(props) {
  const history = useHistory();

  const { setCalendarData, setAuthenticatedUser } = props;

  useEffect(() => {
  console.log('Name&Email: ', name, email)
  }, [CalendarItems, email, name])

  const onSuccess = (resp) => {
    console.log("[Login Success] currentUser:", resp.profileObj);
    setToken(resp.accessToken);
    setAuthenticatedUser(resp);
    refreshTokenSetup(resp);
    
    let events = getEvents(resp.accessToken, resp.profileObj.email);
    events
      .then((res) => {
        setCalendarData(res.data.items);
        console.log('JustResponse', res)
        email = resp.profileObj.email;
        name = resp.profileObj.name;
        history.push('/activity');
      })
      .catch((err) => {
        console.log("error message:", err);
      });
  };

  const onFailure = (resp) => {
    console.log("[Login failed] res: ", resp);
  };

  return (
    <div>
      <GoogleLogin
        render={renderProps => (
          <button onClick={renderProps.onClick} className="googleLogInOut Activity__Link--Button" disabled={renderProps.disabled}>
            <img src={GoogleLogo} alt="" className="google__logo--img"/> 
            Sign in with Google
            </button>
        )}
        buttonText="Sign in with Google"
        // className="googleLogInOut Activity__Link--Button"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "100px" }}
        isSignedIn={false}
        scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
      />
    </div>
  );
}

export default Login;
