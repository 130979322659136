/** @format */

import React from "react";
import { Link } from "react-router-dom";
import PageNotFound from "./Assets/PageNotFound.png";
import "./NotFoundPage.css";

class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <img className="NotFoundPage-image" src={PageNotFound} />
      </div>
    );
  }
}
export default NotFoundPage;
