/** @format */

import React, { useState, useEffect } from "react";
import Hamburger from "../../Assets/hamburger-menu.png";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import ContactModal from "./ContactModal";
import MyTimeLogo from "../../Assets/My_Time_Logo.png";
import "./Navbar.scss";
// import Modal from './DeleteWarehouse';

function Navbar() {
  const [navbar, setNavbar] = useState(false);
  // const [modal, setModal] = useState(false);

  //   const onToggle = () => {
  //   setModal(true);
  // }

  // function cancelDelete(){
  //   setModal(false);
  // }

  // if (modal === true) {
  //   return (
  //   <Modal onCancel={() => cancelDelete()} />
  //   )
  // }

  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <img
      className="Navbar__Right--Hamburger"
      src={Hamburger}
      alt="Hamburger Menu"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
  ));

  const changeBackground = () => {
    if (window.scrollY >= 96) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div className={navbar ? "Navbar active" : "Navbar"}>
      <div className="Navbar__Header--Container">
        <Link to="/">
          <img
            // onClick={() => onToggle()}
            src={MyTimeLogo}
            alt=""
            className="img-fluid rounded d-none d-sm-block my-time-logo"
          />{" "}
        </Link>
        <Link
          to="/"
          className="Navbar__Header text-decoration-none text-dark my-time-text"
        >
          my time
        </Link>
      </div>
      <div className="Navbar__Right">
        <h3 className="Navbar__Right--Login">
          <ContactModal />
        </h3>
        <Link
          to="/activity"
          className="Navbar__Right--Signup text-decoration-none text-white"
          id="scheduleTimeLink"
        >
          Schedule time
        </Link>
      </div>
    </div>
  );
}

export default Navbar;

// <Dropdown className="Navbar__Right--Hamburger">
//   <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
//     <img
//       className="Navbar__Right--Hamburger"
//       src={Hamburger}
//       alt="Hamburger Menu"
//     />
//   </Dropdown.Toggle>

//   <Dropdown.Menu
//     className="Navbar__dropdown--Menu"
//     id="navbar-menu-drop"
//   >
//     <Dropdown.Item eventKey="1" id="navbar-link-drop">
//       <Link to="/" className="Navbar__Nav--Links" id="navbar-link">
//         Home
//       </Link>
//     </Dropdown.Item>
//     <Dropdown.Item eventKey="2" id="navbar-link-drop">
//       <Link
//         to="/activity"
//         className="Navbar__Nav--Links"
//         id="navbar-link"
//       >
//         Activity
//       </Link>
//     </Dropdown.Item>
//     <Dropdown.Item eventKey="4" id="navbar-link-drop">
//       <Link
//         to="/scheduling"
//         className="Navbar__Nav--Links"
//         id="navbar-link"
//       >
//         Scheduling
//       </Link>
//     </Dropdown.Item>
//     <Dropdown.Item eventKey="3" id="navbar-link-drop">
//       <Link
//         to="/schedules"
//         className="Navbar__Nav--Links"
//         id="navbar-link"
//       >
//         Schedules
//       </Link>
//     </Dropdown.Item>
//   </Dropdown.Menu>
// </Dropdown>
