import React, { useState } from 'react';
import "../Scheduling/Scheduling.scss";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import { Icon } from "@iconify/react";

function ActivityItem(props){
    const [duration, setDuration] = useState('');
    const [frequency, setFrequency] = useState('');
    const [time, setTime] = useState('');

    const {
      activity,  
      emoji, 
      name, 
      id,
      index,
      deleteFromSchedule,
    } = props;

    console.log('Selected Activities in Activity.js: ', activity);

    return (
    <div className="prefrence-accordion" id="background-box">
        <div className="Rectangle--Middle">
        <Accordion>
            <Accordion
            id="Scheduling__Rectangle--Middle"
            >
            <Accordion.Item eventKey="0" id="background-box">
                <Accordion.Header
                className="Scheduling__Rectangle--Middle"
                id="accordiodn-custom-header"
                >
                <div className="Scheduling__Rectangle--Header-Wrapper">
                    <div className="Scheduling__Rectangle--Left-Header">
                    <div className="Scheduling__Rectangle--Middle-IconsandTitle">
                        <div className="Scheduling__Rectangle--Middle-Activity-Wrapper">
                        <Icon
                            className="Scheduling__Iconify--Icon"
                            icon={emoji}
                            inline={true}
                        />
                        <h3 className="Scheduling__Rectangle--Middle-Header">
                            {name}
                        </h3>
                        </div>
                        <div>
                        <Icon
                            className="Scheduling__Iconify--Trash"
                            icon="bi:trash"
                            id={id}
                            onClick={() => {
                            deleteFromSchedule(id, index);
                            }}
                        />
                        </div>
                    </div>
                    </div>
                    <div className="Scheduling__Rectangle--Right-Header">
                    <Icon
                        className="Scheduling__Iconify--Dropdown"
                        icon="ri:arrow-drop-down-line"
                    />
                    <Icon
                        className="Scheduling__Iconify--Drag"
                        icon="ic:round-drag-handle"
                    />
                    </div>
                </div>
                </Accordion.Header>
                <Accordion.Body>
                <div className="Scheduling__Input--Container">
                    <div className="Scheduling__Input--Wrapper">
                    <h2 className="Scheduling__Input--Header">
                        How much time do you want to dedicate to each
                        ocurrence?
                        <DropdownButton
                        className="Scheduling__Input--Dropdown dropdown-basic-button"
                        title={duration ? duration : "15 minutes"}
                        onSelect={(e) => {
                            console.log('Selected Dropdown:', e);
                            setDuration(e);
                            props.handleDurationSelection(e, index);
                        }}
                        >
                        <Dropdown.Item
                            eventKey='15 minutes'
                            className="dropdown-basic-button-item"
                        >
                            15 minutes
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='30 minutes'
                            className="dropdown-basic-button-item"
                        >
                            30 minutes
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='1 hour'
                            className="dropdown-basic-button-item"
                        >
                            1 hour
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='1.5 hour'
                            className="dropdown-basic-button-item"
                        >
                            1.5 hour
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='2 hours'
                            className="dropdown-basic-button-item"
                        >
                            2 hours
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='2.5 hours'
                            className="dropdown-basic-button-item"
                        >
                            2.5 hours
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='3 hours'
                            className="dropdown-basic-button-item"
                        >
                            3 hours
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='3.5 hours'
                            className="dropdown-basic-button-item"
                        >
                            3.5 hours
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='4 hours'
                            className="dropdown-basic-button-item"
                        >
                            4 hours
                        </Dropdown.Item>
                        </DropdownButton>
                    </h2>
                    </div>
                    <div className="Scheduling__Input--Wrapper">
                    <h2 className="Scheduling__Input--Header">
                        How often do you want to do this activity?
                        <DropdownButton
                        className="Scheduling__Input--Dropdown dropdown-basic-button"
                        title={frequency ? frequency : "every day"}
                        onSelect={(e) => {
                            setFrequency(e);
                            props.handleFrequencySelection(e, index);
                        }}
                        >
                        <Dropdown.Item
                            eventKey='every day'
                            className="dropdown-basic-button-item"                                
                        >
                            every day
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='every workday'
                            className="dropdown-basic-button-item"
                        >
                            every workday
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='weekend only'
                            className="dropdown-basic-button-item"
                        >
                            weekend only
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='1x per week'
                            className="dropdown-basic-button-item"
                        >
                            1x per week
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='2x per week'
                            className="dropdown-basic-button-item"                               
                        >
                            2x per week
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='3x per week'
                            className="dropdown-basic-button-item"                               
                        >
                            3x per week
                        </Dropdown.Item>
                        </DropdownButton>
                    </h2>
                    </div>
                    <div className="Scheduling__Input--Wrapper">
                    <h2 className="Scheduling__Input--Header">
                        When do you prefer to do this activity?
                        <DropdownButton
                        className="Scheduling__Input--Dropdown dropdown-basic-button"
                        title={time ? time : "morning"}
                        onSelect={(e) => {
                            setTime(e);
                            props.handleTimeSelection(e, index);
                        }}
                        >
                        <Dropdown.Item
                        eventKey='early morning'
                            className="dropdown-basic-button-item"
                        >
                            early morning
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='morning'
                            className="dropdown-basic-button-item"
                        >
                            morning
                        </Dropdown.Item>
                        <Dropdown.Item
                        eventKey='afternoon'
                            className="dropdown-basic-button-item"
                        >
                            afternoon
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='evening'
                            className="dropdown-basic-button-item"
                        >
                            evening
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='late evening'
                            className="dropdown-basic-button-item"
                        >
                            late evening
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey='surprise me'
                            className="dropdown-basic-button-item"
                        >
                            surprise me
                        </Dropdown.Item>
                        </DropdownButton>
                    </h2>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </Accordion>
        </div>
    </div>
    );
}

export default ActivityItem;