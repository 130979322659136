/** @format */
import { nanoid } from "nanoid";
import React, { useState, useEffect } from "react";
import Form from "./Form";
import FilterButton from "./FilterButton";
import Navbar from "./Components/Navbar/Navbar.js";
import Home from "./Home";
import Activity from "./Components/Activity/Activity";
import Schedules from "./Components/Schedule/Schedules";
import Scheduling from "./Components/Scheduling/Scheduling.js";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import Gradient from "./Assets/Background_Gradient.svg";
import axios from "axios";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/customParseFormat";
import SetActivities from "./Data/Set-Activities.json";
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import { CalendarItems } from "./login";
import { getEvents, createEvent, accessToken } from "./CalendarHelper";
import moment from "moment";
import NotFoundPage from "./NotFoundPage";

dayjs.extend(advancedFormat);

function App(props) {
  const { value } = props;
  const [newActivity, setNewActivity] = useState({});
  const [activityList, setActivityList] = useState(SetActivities);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [schedulingOptions, setSchedulingOptions] = useState([]);
  const [authenticatedUser, setAuthenticatedUser] = useState({});


  function handleDurationSelection(e, index){
    let results = [...schedulingOptions];
    if(e === '15 minutes'){
      results[index].duration = 15;
    } else if(e === '30 minutes'){
      results[index].duration = 30;
    } else if(e === '1 hour'){
      results[index].duration = 60;
    } else if(e === '1.5 hour'){
      results[index].duration = 90;
    } else if(e === '2 hours'){
      results[index].duration = 120;
    } else if(e === '2.5 hours'){
      results[index].duration = 150;
    } else if(e === '3 hours'){
      results[index].duration = 180;
    } else if(e === '3.5 hours'){
      results[index].duration = 210;
    } else if(e === '4 hours'){
      results[index].duration = 240;
    } else {
      console.log('error!!! Nothing got picked.')
    }
    setSchedulingOptions(results);
  }

  //  Get random day of the week
   function getRandomDay(arr){
    let idx = [Math.floor(Math.random() * arr.length)];
    return arr[idx]
   }

  //  Frequency (number of times per day/week)
   function handleFrequencySelection(e, index) {
    let daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    let idx = [Math.floor(Math.random() * daysOfWeek.length)];
    let results = [...schedulingOptions];
    
    if(e === 'every day'){
      results[index].recurrence = ["RRULE:FREQ=DAILY"]
    } else if(e === 'every workday') {
      results[index].recurrence = "RRULE:FREQ=WEEKLY;BYDAY=FR,MO,TH,TU,WE"
    } else if(e === 'weekend only'){
      results[index].recurrence = ["RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=SA,SU"]
    } else if(e === '1x per week'){
      results[index].recurrence = [`RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=${daysOfWeek[idx]}`]
    } else if(e === '2x per week'){
      results[index].recurrence = [`RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=${daysOfWeek[idx]},${daysOfWeek[idx]}`]
    } else if(e === '3x per week'){
      results[index].recurrence = [`RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=${daysOfWeek[idx]},${daysOfWeek[idx]},${daysOfWeek[idx]}`]
    }
    setSchedulingOptions(results);
    console.log('frequency results: ', results);
  }

  function chooseRandomTime(startT, endT, index){
    const startTime = dayjs(new Date(dayjs(new Date()).format(`YYYY-MM-DDT${startT}`)));
    const endTime = dayjs(new Date(dayjs(new Date()).format(`YYYY-MM-DDT${endT}`)));

    let time = [];
    let finished = true;
    while(finished){
      if(time.length > 0){
        if(time[time.length - 1] < endTime){
          time.push(time[time.length - 1].add(15, 'minute'));
        } else {
          time.splice(-1);
          finished = false;
        }
      } else {
        time.push(startTime);
      } 
    }
    console.log('random time: ', time);
    const start = Math.floor(Math.random() * time.length);
    console.log('Whole schedulingOptions: ', schedulingOptions, index);
    return {
      start: time[start],
      end: time[start].add(schedulingOptions[index].duration, 'minute')
    }
  }

  // Time (Start and End Times)
  function handleTimeSelection(e, index) {
    let earlyMorningStart = '05:00:00'
    let earlyMorningEnd = '08:00:00'
    let morningStart = '08:00:00'
    let morningEnd = '12:00:00'
    let afternoonStart = '13:00:00'
    let afternoonEnd = '17:00:00'
    let eveningStart = '17:00:00'
    let eveningEnd = '20:00:00'
    let lateEveningStart = '20:00:00'
    let lateEveningEnd = '23:00:00'

    let results = [...schedulingOptions];
    results[index].timeSelection = e;
    if(e === 'early morning'){
      const time = chooseRandomTime(earlyMorningStart, earlyMorningEnd, index);
      results[index].start = time.start.format('YYYY-MM-DDTHH:mm:ss');
      results[index].end = time.end.format('YYYY-MM-DDTHH:mm:ss');
    } else if(e === 'morning'){
      const time = chooseRandomTime(morningStart, morningEnd, index);
      results[index].start = time.start.format('YYYY-MM-DDTHH:mm:ss');
      results[index].end = time.end.format('YYYY-MM-DDTHH:mm:ss');
    } else if(e === 'afternoon'){
      const time = chooseRandomTime(afternoonStart, afternoonEnd, index);
      results[index].start = time.start.format('YYYY-MM-DDTHH:mm:ss');
      results[index].end = time.end.format('YYYY-MM-DDTHH:mm:ss');
    } else if(e === 'evening'){
      const time = chooseRandomTime(eveningStart, eveningEnd, index);
      results[index].start = time.start.format('YYYY-MM-DDTHH:mm:ss');
      results[index].end = time.end.format('YYYY-MM-DDTHH:mm:ss');
    } else if(e === 'late evening'){
      const time = chooseRandomTime(lateEveningStart, lateEveningEnd, index);
      results[index].start = time.start.format('YYYY-MM-DDTHH:mm:ss');
      results[index].end = time.end.format('YYYY-MM-DDTHH:mm:ss');
    } else if(e === 'surprise me'){
      let stringArr = ['early morning', 'morning', 'afternoon', 'evening', 'late evening'];
      let idx = [Math.floor(Math.random() * stringArr.length)];
      handleTimeSelection(stringArr[idx], index);
    }
    setSchedulingOptions(results);
  }

  function populateSchedulingOptions(){
    if(schedulingOptions.length < 1){
      let results = [];
        console.log('useEffect Activities: ', selectedActivities)
      selectedActivities.forEach(activity => {
        results.push({
          summary: activity.name,
          start: dayjs(new Date()).format("YYYY-MM-DDT08:00:00"),
          end: dayjs(new Date()).format("YYYY-MM-DDT12:00:00"),
          recurrence: ["RRULE:FREQ=DAILY"],
          duration: 15,
          timeSelection: 'morning'
        })
      })
      setSchedulingOptions(results);
      }
  }

 
  
  function prepReactBigCalendar() {
    console.log('PrepCalendar in APP: ', schedulingOptions)
        const calData = schedulingOptions.map(event => {
          return {
            calendarId: authenticatedUser.profileObj.email,
            userGenerated: true,
            start: {
              dateTime: event.start + '-07:00',
              timeZone: "America/Los_Angeles"
            },
            end: {
              dateTime: event.end + '-07:00',
              timeZone: "America/Los_Angeles"
            },
            recurrence: event.recurrence,
            summary: event.summary
          }
        })
        setCalendarData([...calendarData, ...calData]);
        console.log('PrepReactBigCalendar: ', schedulingOptions, [...calendarData, ...schedulingOptions]);
    }

  function createGMAILEvents() {
    const url =
      "https://www.googleapis.com/calendar/v3/calendars/" +
      encodeURI(authenticatedUser.profileObj.email) +
      "/events";
    const bearer = `Bearer ${authenticatedUser.accessToken}`;
    const calendarBody = calendarData.filter((data) => data.userGenerated);
    console.log("CalendarBody App:", calendarBody);
    calendarBody.forEach((item) => {
      axios
        .post(url, item, {
          headers: {
            Authorization: bearer,
          },
        })
    })
  }
  
    
    function rejig(){
      schedulingOptions.forEach((event, index) => {
        handleTimeSelection(event.timeSelection, index)
      })
      setSchedulingOptions(schedulingOptions);
    }
  
    // CREATE NEW ACTIVITY
    function createNewActivity(value) {
      addToActivityList(
        {
          id: nanoid(), 
          name: value,  
          emoji: "twemoji:flexed-biceps",
          completed: false, 
          selected: true
        });
  }

  function rejig() {
    schedulingOptions.forEach((event, index) => {
      handleTimeSelection(event.timeSelection, index);
    });
    setSchedulingOptions(schedulingOptions);
  }

  // CREATE NEW ACTIVITY
  function createNewActivity(value) {
    addToActivityList({
      id: nanoid(),
      name: value,
      emoji: "twemoji:flexed-biceps",
      completed: false,
      selected: true,
    });
  }

  // Add activity to list
  function addToActivityList(activity) {
    setActivityList([...activityList, activity]);
    setSelectedActivities([...selectedActivities, activity]);
  }

  // Toggle activity selected/unselected
  function toggleSelected(id) {
    const activities = [...activityList];
    const selected = activityList.filter(
      (activity) => activity.selected === "selected"
    );
    activities.forEach((activity) => {
      if (activity.id === id) {
        if (
          activity.selected === "selected" ||
          activity.selected === "disabled"
        ) {
          activity.selected = "unselected";
        } else if (selected.length > 2) {
          activity.selected = "disabled";
        } else {
          activity.selected = "selected";
        }
      }
      return activity;
    });
    const finalList = activityList.filter(
      (activity) => activity.selected === "selected"
    );
    setActivityList(activities);
    setSelectedActivities(finalList);
  }

  // Delete activity
  function deleteFromSchedule(id, index) {
    const activities = [...selectedActivities];
    const remainingSelected = activities.filter(
      (activity) => id !== activity.id
    );
    setSelectedActivities(remainingSelected);
    toggleSelected(id);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route
            exact
            path="/"
            exact
            render={() => (
              <Home
                setCalendarData={setCalendarData}
                setAuthenticatedUser={setAuthenticatedUser}
              />
            )}
          />
          <Route
            exact
            path="/activity"
            exact
            render={() => (
              <Activity
                createNewActivity={createNewActivity}
                activityList={activityList}
                toggleSelected={toggleSelected}
                selectedActivities={selectedActivities}
                setSelectedActivities={setSelectedActivities} 
                value={value} 
                populateSchedulingOptions={populateSchedulingOptions}
              />)} />
            <Route exact 
              path="/schedules" 
              exact render={
                () => ( 
                <Schedules
                calendarData={calendarData}
                selectedActivities={selectedActivities}
                createGMAILEvents={createGMAILEvents}
                rejig={rejig}
              />
            )}
          />
          <Route
            exact
            path="/scheduling"
            exact
            render={() => (
              <Scheduling
                newActivity={newActivity}
                selectedActivities={selectedActivities}
                setSelectedActivities={setSelectedActivities}
                activityList={activityList}
                deleteFromSchedule={deleteFromSchedule}
                setSchedulingOptions={setSchedulingOptions}
                handleDurationSelection={handleDurationSelection}
                handleFrequencySelection={handleFrequencySelection}
                handleTimeSelection={handleTimeSelection}
                prepReactBigCalendar={prepReactBigCalendar}
                />)} />
          </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
