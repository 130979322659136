/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Return from "../../Assets/Return_Symbol.svg";
import PlusCircleBlack from "../../Assets/plus-circle-white.svg";
import ActivityForm from "./ActivityForm";
import { Icon } from "@iconify/react";
import { Modal, Button } from "react-bootstrap";
import Emojis from "./Emojis";
import { nanoid } from "nanoid";
import "./Activity.scss";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Activity(props) {
  const [isEditing, setEditing] = useState(false);
  const wasEditing = usePrevious(isEditing);
  const [newActivity, setNewActivity] = useState("");
  const {
    createNewActivity,
    activityList,
    toggleSelected,
    selectedActivities,
    value,
    populateSchedulingOptions
  } = props;

  function handleChange(e) {
    setNewActivity(e.target.name);
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  // const handleKeypress = (e) => {
  //   if (e.keyCode === 13) {
  //     this.btn.click();
  //   }
  // };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Space") {
  //     this.refs.link.click();
  //   }
  // };

  const newActivityButton = (
    <div className="Activity__Input--Row">
      <form onSubmit={handleSubmit}>
        <div
          className="Activity__Input--Wrapper--New 
          Create-New-Activity"
          onClick={() => setEditing(true)}
        >
          <img src={PlusCircleBlack} alt="" />
          <label className="Activity__Input--Label" htmlFor="add-activity">
            <input
              className="Activity__Input--Input--New 
            Create-New-Activity"
              id="add-activity"
              type="button"
              value="Create Your Own"
              onChange={handleChange}
            />
          </label>
        </div>
      </form>
    </div>
  );

  const newActivityInput = (
    <div>
      <ActivityForm
        createNewActivity={createNewActivity}
        activityList={activityList}
        value={value}
        setEditing={setEditing}
      />
    </div>
  );

  const renderActivities = activityList.map((activity, index) => {
    const { id } = activity;

    return (
      <div
        key={index}
        index={index}
        id={activity.id}
        className={
          activity.selected === 'selected'
            ? "Activity__Input--Wrapper Selected "
            : activity.selected === 'disabled'
            ? "Activity__Input--Wrapper ThreeSelected"
            : "Activity__Input--Wrapper"
        }
        name={activity.name}
        onClick={() => {
          toggleSelected(id);
        }}
      >
        <Icon
          className="Activity__Input--Iconify"
          icon={activity.emoji}
          inline={true}
        />
        <label
          className="Activity__Input--Label"
          htmlFor={activity.name}
          name={activity.name}
        >
          <input
            className="Activity__Input--Input"
            id={activity.name}
            type="button"
            value={activity.name}
            name={activity.name}
          />
        </label>
      </div>
    );
  });

  return (
    <div className="Activity">
      <div className="Activity__Wrapper">
        <h2 className="Activity__Header">
          What do you want to make time for this week?
        </h2>
        <h4 className="Activity__Sub-Header">
          Choose up to 3 activities you want to plan into your schedule
        </h4>
        <div className="Activity__Input--Row">
          {/* Rendering activities */}
          {renderActivities}
        </div>
        <div>
          <div>{isEditing ? newActivityInput : newActivityButton}</div>
        </div>
      </div>

      <Link
        className="Activity__Link--Button"
        to="/scheduling"
        id="link"
        onClick={() => {populateSchedulingOptions()}}
      >
        Next
      </Link>
      <div className="Activity__Link--Button-Footer-Wrapper">
        <p className="Activity__Link--Button-Footer-Text">or Press Enter</p>
        <img
          className="Activity__Link--Button-Footer-Return"
          src={Return}
          alt=""
        />
      </div>
    </div>
  );
}

export default Activity;
