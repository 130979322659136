/** @format */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import ModalDialog from "react-bootstrap/ModalDialog";
// import ModalHeader from "react-bootstrap/ModalHeader";
// import ModalTitle from "react-bootstrap/ModalTitle";
// import ModalBody from "react-bootstrap/ModalBody";
// import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./Navbar.scss";
import { ModalBody, ModalFooter } from "react-bootstrap";

export default function ContactModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   .ajax({
  //     url: "https://formsubmit.co/ajax/hanse_hedegaard@yahoo.dk",
  //     method: "POST",
  //     data: {
  //       name: "FormSubmit",
  //       message: "I'm from Devro LABS",
  //     },
  //     dataType: "json",
  //   });

  return (
    <>
      <a variant="primary" onClick={handleShow}>
        Contact us
      </a>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Please tell us what is on your mind!</Modal.Title>
        </Modal.Header>

        <form
          method="POST"
          action="https://formsubmit.co/80fd24ed30f5e4a7a40e2f0efccae24c"
          enctype="multipart/form-data"
        >
          <ModalBody className="m-3 ">
            <div className="row" id="contact-form-row">
              <h3>Your email</h3>
              <input
                type="email"
                name="email"
                placeholder="email@email.com"
                className="mail-input-contact-form mb-3 display-6"
                size="lg"
              />
              <h3>Your message for us</h3>
              <textarea
                name="message"
                placeholder="Describe here.."
                className="mb-5 display-6"
                rows={10}
                size="lg"
                font-size="28px"
              ></textarea>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-primary btn-lg">
              Send Message
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

// render(<ContactModal />);
