/** @format */

import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Sparkle_Emoji from "./Assets/Sparkle_Emoji.svg";
import HomeImg from "./Assets/Home.svg";
import LandingPageImage from "./Assets/Landing_Page_Image_Desktop.png";
import LandingPageStep1 from "./Assets/Landing_Page_Step_1.png";
import LandingPageStep2 from "./Assets/Landing_Page_Step_2.png";
import LandingPageStep3 from "./Assets/Landing_Page_Step_3.png";
import Login from "./login.js";
import Logout from "./logout.js";
import "./Home.css";

import Fade from "react-bootstrap/Fade";
// import LandingPageImage from "../src/Assets/landing_page_image.png";
// import TestForFreeBusy from "./TestForFreeBusy";

function Home(props) {
  const { setCalendarData, setAuthenticatedUser } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="home">
      <div>
        <div class="overlook-of-projects">
          <div class="project-overwiew-container">
            <div class="row">
              <div class="col-md-7">
                <div class="project-overview-description">
                  {/* <button>sign out</button> */}
                  <h1 className="home-heading">Make time for yourself</h1>
                  <h2 className="home-hero-p">
                    Setting time for yourself shouldn’t be
                    <br /> difficult, but we know how overwhelming
                    <br />
                    it can feel. In a few clicks, generate a plan
                    <br /> and start investing time back towards
                    <br /> your goals
                  </h2>
                  <div className="text-center">
                    <div className="row">
                      <div className="col-md-4">
                        <Login
                          setCalendarData={setCalendarData}
                          setAuthenticatedUser={setAuthenticatedUser}
                          autoLoad={false}
                          className="Activity__Link--Button"
                          id="loginToGoogle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="LandingImage">
                  <img
                    src={LandingPageImage}
                    alt=""
                    className="img-fluid rounded d-none d-sm-block"
                  />
                </div>
              </div>
              <br />
              <br />
              <div className="row three-step-overview">
                <div className="col-md-4">
                  <img
                    src={LandingPageStep1}
                    alt=""
                    className="img-fluid rounded d-none d-sm-block step1"
                  />

                  <div className="vl">
                    <div className="step-1">
                      <h4 className="home-h4">
                        Tell us what you
                        <br />
                        want to accomplish
                      </h4>
                    </div>
                    <div class="project-overview-description ">
                      <p className="home-p">
                        My Time can help you fit in the things you want to do
                        with the things you have to do. Tell us what you want to
                        accomplish this week and we’ll figure out the rest
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <img
                    src={LandingPageStep2}
                    alt=""
                    className="img-fluid rounded d-none d-sm-block step2"
                  />
                  <div className="vl">
                    <h4 className="home-h4">Rejig to your heart’s content</h4>
                    <div class="project-overview-description">
                      <p className="home-p">
                        Generate mutiple schedule options and choose the best
                        one for your week
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <img
                    src={LandingPageStep3}
                    alt=""
                    className="img-fluid rounded d-none d-sm-block step2"
                  />
                  <div className="vl">
                    <h4 className="home-h4">Add to your Google calendar</h4>
                    <div class="project-overview-description">
                      <p className="home-p">
                        Once you’ve found the desired schedule, add the events
                        to your Google Calendar
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-home-page">
          <h5 className="home-h5 landing-body">
            Reach your personal goals,
            <br />
            one week at a time
          </h5>
          <p className="home-p-bottom-page">
            Setting time for yourself shouldn’t be
            <br />
            difficult, but we know how overwhelming it
            <br />
            can feel.
          </p>
          <p className="home-p-bottom-page ">
            In a few clicks, generate a plan and start
            <br />
            investing time back towards your goals
          </p>
          <h6 className="home-h6">
            With my time, spend less time planning and more time doing
          </h6>

          {/* <Link
            className="Activity__Link--Button"
            to="/schedules"
            id="link-to-google-calendar"
          >
            <h3 className="Activity__Link--Button">
              Sign in with Google
            </h3>
          </Link> */}
          <div className="col-md-4 lowerGoogle__login--container">
                <Login
                  setCalendarData={setCalendarData}
                  setAuthenticatedUser={setAuthenticatedUser}
                  autoLoad={false}
                  className="Activity__Link--Button"
                  id="loginToGoogle"
                />
          </div>
          <p className="copyright-p">
            Copyright © 2021• Time All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
