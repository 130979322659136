/** @format */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import ModalDialog from "react-bootstrap/ModalDialog";
// import ModalHeader from "react-bootstrap/ModalHeader";
// import ModalTitle from "react-bootstrap/ModalTitle";
// import ModalBody from "react-bootstrap/ModalBody";
// import ModalFooter from "react-bootstrap/ModalFooter";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

import "./Schedules.css";
import { ModalBody, ModalFooter } from "react-bootstrap";
import { render } from "@testing-library/react";

export default function SuccessModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   .ajax({
  //     url: "https://formsubmit.co/ajax/hanse_hedegaard@yahoo.dk",
  //     method: "POST",
  //     data: {
  //       name: "FormSubmit",
  //       message: "I'm from Devro LABS",
  //     },
  //     dataType: "json",
  //   });

  const styles = {
    border: "4px solid rgba(0, 0, 0,)",
  };

  return (
    <>
      <a
        variant="primary"
        className="Shuffle__Calendar--Button text-center"
        onClick={handleShow}
        style={{ textDecoration: "none" }}
      >
        <button>done</button>
      </a>

      <Modal
        show={show}
        onHide={handleClose}
        // size="sm"
        style={styles}
        dialogClassName="modal-60w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />

        <ModalBody className="m-3 ">
          <h1 className="Success-Modal-h1">Added!</h1>
          <div className="row">
            <Link to="/activity" style={{ textDecoration: "none" }}>
              <h3 className="Back-To-Scheduling text-center">
                Back to scheduling
              </h3>
            </Link>
            <a
              href="https://calendar.google.com/calendar/u/0/r"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <h3 className="Open-Google-Calendar text-center">
                Open my Google Calendar
              </h3>
            </a>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

// render(<ContactModal />);
