/** @format */
import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import Return from "../../Assets/Return_Symbol.svg";
import "./Scheduling.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/customParseFormat";
import ActivityItem from '../Activity/ActivityItem';

function Scheduling(props) {
  const {
    activityList,
    selectedActivities,
    deleteFromSchedule,
    setSchedulingOptions,
    handleTimeSelection,
    handleFrequencySelection,
    handleDurationSelection,
    prepReactBigCalendar
  } = props;

  console.log('Selected in scheduling.js:', selectedActivities);


  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

 
  

  return (
    <div className="Scheduling">
      <div className="Scheduling__Header--Wrapper">
        <h2 className="Scheduling__Header">Great!</h2>
        <h2 className="Scheduling__Header">
          Now let's select some scheduling preferences
        </h2>
        <h4 className="Scheduling__Sub-Header">
          This will help us personalize your my time experience
        </h4>
      </div>
      <div className="preferences-container">
          <div className="dragdrop--container">
              {selectedActivities.map((activity, index) => {
                const {emoji, name, id} = activity;
                if(activity.selected === 'selected'){
                return(
                  <ActivityItem
                      key={index}
                      index={index}
                      activity={activity}
                      emoji={emoji}
                      name={name}
                      id={id}
                      deleteFromSchedule={deleteFromSchedule}
                      handleTimeSelection={handleTimeSelection}
                      handleFrequencySelection={handleFrequencySelection}
                      handleDurationSelection={handleDurationSelection}
                  />
                )
              }
              })}
          </div>
      </div>
      <Link
        className="Activity__Link--Button"
        to="/schedules"
        onClick={() => prepReactBigCalendar()}
      >
        Finish
      </Link>
      <div className="Activity__Link--Button-Footer-Wrapper">
        <p className="Activity__Link--Button-Footer-Text">or Press Enter</p>
        <img
          className="Activity__Link--Button-Footer-Return"
          src={Return}
          alt=""
        />
      </div>
    </div>
  );
}

export default Scheduling;