/** @format */

import axios from "axios";
import { email, name } from "./login";

export let accessToken = "";

export async function getEvents(accessToken, fullName) {
  const bearer = `Bearer ${accessToken}`;

  const url =
    "https://www.googleapis.com/calendar/v3/calendars/" +
    encodeURI(fullName) +
    "/events";

  return await axios.get(url, {
    headers: {
      Authorization: bearer,
    },
  });
}

// export async function getFreeBusy(accessToken, fullName) {
//   const bearer = `Bearer ${accessToken}`;

//   const lib = require("lib")({
//     token: `Bearer ${accessToken}`,
//   });

//   // make API request
//   let result = await lib.gcal.freebusy["@3.0.0"].query({
//     timeMin: `00:00:00`,
//     timeMax: `23:59:59`,
//     timeZone: `CST`,
//     groupExpansionMax: 50,
//     calendarExpansionMax: 50,
//   });

//   const url =
//     "https://www.googleapis.com/calendar/v3/calendars/" +
//     encodeURI(fullName) +
//     "/freeBusy";

//   return await axios.post(url, {
//     headers: {
//       Authorization: bearer,
//     },
//   });
// }
// use "lib" package from npm

export function setToken(token) {
  accessToken = token;
}


